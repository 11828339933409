/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FaUser, FaPhone } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';
import {
  InputText, InputNumber, InputEmail, ErrorField,
} from '../../lib/HooksFormFields';
import StyledSelect from '../../lib/HooksFormFields/StyledSelect/Select';
import styles from './SalesPointForm.module.scss';
import { updateSalesPointAction } from '../../actions/salesPoints';
import { useAppDispatch } from '../../reducers';
import { OptionType } from '../../types';
import pen from '../../assets/images/pen.svg';
import InputFile from '../InputFile/InputFile';

export default function SalesPointForm() {
  const dispatch = useAppDispatch();
  const { user } = useSelector((d: any) => d.authReducer);
  const salesPointsReducer = useSelector((d: any) => d.salesPointsReducer);
  const {
    salesPoint, lists,
  } = salesPointsReducer;

  const [isForm, setIsForm] = useState<boolean>(false);

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const strongContract = watch('infos.strongContract');
  const emailError : string = (errors.contact as any)?.email?.message || '';

  useEffect(() => {
    if (salesPoint) {
      reset(salesPoint);
    }
  }, [salesPoint]);

  async function onSubmit(values: FieldValues) {
    await updateSalesPointAction(dispatch, salesPoint._id, values);
    setIsForm(false);
  }

  return (
    <div className={styles.container}>
      {!isForm
        ? <button
          type='button'
          className={styles.toggle}
          onClick={() => setIsForm(true)}
        >
          Edition
        </button>
        : <button
          type='button'
          className={styles.toggle}
          onClick={handleSubmit(onSubmit)}
        >
          Valider
        </button>
      }
      {isForm
        ? <div className={styles.form}>
            <h3>Détails</h3>
            <section>
              {salesPoint?.customerStatus.includes('lead') && user?.agentCode && salesPoint?.siret
                && <div className={styles['form-field']}>
                  <label>Statut :</label>
                  <div className={styles.input}>
                    <StyledSelect
                      name="customerStatus"
                      options={lists.prospectStatus}
                      control={control}
                    />
                  </div>
                </div>
              }
              <div className={styles['form-field']}>
                <label>Structure :</label>
                <div className={styles.input}>
                  <StyledSelect
                    name="infos.structureType"
                    options={lists.structuresTypesOptions}
                    control={control}
                  />
                </div>
              </div>
              <div className={styles['form-field']}>
                <label>Contrat fort :</label>
                <div className={styles.boolean}>
                  <button
                    type='button'
                    onClick={() => setValue('infos.strongContract', true)}
                    className={strongContract === true ? styles.active : ''}
                  >
                    Oui
                  </button>
                  <button
                    type='button'
                    onClick={() => setValue('infos.strongContract', false)}
                    className={strongContract === false ? styles.active : ''}
                  >
                    Non
                  </button>
                </div>
              </div>
              <div className={styles['form-field']}>
                <label>Fournisseur :</label>
                <div className={styles.input}>
                  <InputText
                    name="infos.provider"
                    control={control}
                    className="secondary"
                    rules={{ }}
                  />
                </div>
              </div>
              <div className={styles['form-field']}>
                <label>Nombre de place interieure :</label>
                <div className={styles.input}>
                  <InputNumber
                    name="infos.interiorSeats"
                    control={control}
                    className="secondary"
                    rules={{ }}
                  />
                </div>
              </div>
              <div className={styles['form-field']}>
                <label>Nombre de place exterieur :</label>
                <div className={styles.input}>
                  <InputNumber
                    name="infos.exteriorSeats"
                    control={control}
                    className="secondary"
                    rules={{ }}
                  />
                </div>
              </div>
              <div className={styles['form-field']}>
                <label>Coordonnées gps :</label>
                <div className={styles.input}>
                  <InputNumber
                    name="location.lat"
                    control={control}
                    className="secondary"
                    rules={{ }}
                  />
                </div>
                <div className={styles.input}>
                  <InputNumber
                    name="location.lon"
                    control={control}
                    className="secondary"
                    rules={{ }}
                  />
                </div>
              </div>
              <div className={styles['form-field']}>
                <label>Bail commercial :</label>
                <div className={styles.input}>
                  <StyledSelect
                    name="infos.commercialLease"
                    options={lists.commercialLeasesOptions}
                    control={control}
                  />
                </div>
              </div>
            </section>
            <section>
              <h3>Info exploitant</h3>
              <div className={styles['form-field']}>
                <label><FaUser /> Nom exploitant :</label>
                <div className={styles.input}>
                  <InputText
                    name="contact.name"
                    control={control}
                    className="secondary"
                    rules={{ }}
                  />
                </div>
              </div>
              <div className={styles['form-field']}>
                <label><FaPhone /> Téléphone :</label>
                <div className={styles.input}>
                  <InputText
                    name="contact.phone"
                    control={control}
                    className="secondary"
                    rules={{ }}
                  />
                </div>
              </div>
              <div className={styles['form-field']}>
                <label><IoMdMail /> Adresse mail :</label>
                <div className={styles.input}>
                  <InputEmail
                    name="contact.email"
                    control={control}
                    className="secondary"
                  />
                </div>
                {emailError
                  && <ErrorField message={emailError}/>}
              </div>
            </section>
            <section>
              <div className={styles['picture-field']}>
                <label>Illustration</label>
                <InputFile
                  submit={(picture: string) => onSubmit({ picture }) }
                  accept="image/*"
                  capture="environment"
                  id={'salesPoint.picture'}
                >
                  {salesPoint?.picture?.path
                    ? <>
                      <div className={styles.picture}>
                        <div className={styles.icon}>
                          <img src={pen} alt="edit"/>
                        </div>
                        <img src={`${process.env.REACT_APP_API_URL}/public/${salesPoint.picture.path}`}
                          className={styles.img}
                        />
                    </div>
                  </> : <div className={`${styles['empty-picture']}`}>
                      <p>Ajouter une image</p>
                  </div>}
                </InputFile>
              </div>
            </section>
        </div>
        : <div className={`${styles.form}`}>
          <h3>Détails</h3>
          <section>
            <div className={styles.info}>
              <p>Contrat fort :</p>
              {salesPoint?.infos?.hasOwnProperty('strongContract')
                ? <p>{salesPoint?.infos?.strongContract ? 'Oui' : 'Non'}</p>
                : <p><span>non renseigné</span></p>
              }
            </div>
            <div className={styles.info}>
              <p>Siret / Siren :</p>
              <p>{salesPoint?.siret || <span>non renseigné</span>}</p>
            </div>
            <div className={styles.info}>
              <p>Fournisseur :</p>
              <p>{salesPoint?.infos?.provider || <span>non renseigné</span>}</p>
            </div>
            <div className={styles.info}>
              <p>Nombre de place interieure :</p>
              <p>{salesPoint?.infos?.interiorSeats || <span>non renseigné</span>}</p>
            </div>
            <div className={styles.info}>
              <p>Nombre de place exterieur :</p>
              <p>{salesPoint?.infos?.exteriorSeats || <span>non renseigné</span>}</p>
            </div>
            <div className={styles.info}>
              <p>Bail commercial :</p>
              <p>{lists?.commercialLeasesOptions?.find(
                (opt: OptionType) => opt.value && salesPoint?.infos?.commercialLease,
              )?.label || <span>non renseigné</span>}</p>
            </div>
            <div className={styles.info}>
              <p>Coordonnées gps :</p>
              <p>
                {salesPoint?.location?.lat?.toFixed(3) || <span>non renseigné</span>}
                {' / '}
                {salesPoint?.location?.lon?.toFixed(3) || <span>non renseigné</span>}
              </p>
            </div>
          </section>
          <h3>Info exploitant</h3>
          <section>
            <div className={styles.contact}>
              <div className={styles.icon}>
                <FaUser />
              </div>
              <p>{salesPoint?.contact?.name || <span>non renseigné</span>}</p>
            </div>
            <div className={styles.contact}>
              <div className={styles.icon}>
                <FaPhone />
              </div>
              <p>{salesPoint?.contact?.phone || <span>non renseigné</span>}</p>
            </div>
            <div className={styles.contact}>
              <div className={styles.icon}>
                <IoMdMail />
              </div>
              <p>{salesPoint?.contact?.email || <span>non renseigné</span>}</p>
            </div>
          </section>
          <section>
              <div className={styles['picture-field']}>
                <label>Illustration</label>
                <InputFile
                  submit={(picture: string) => onSubmit({ picture }) }
                  accept="image/*"
                  capture="environment"
                  id={'salesPoint.picture'}
                >
                  {salesPoint?.picture?.path
                    ? <>
                      <div className={styles.picture}>
                        <div className={styles.icon}>
                          <img src={pen} alt="edit"/>
                        </div>
                        <img src={`${process.env.REACT_APP_API_URL}/public/${salesPoint.picture.path}`}
                          className={styles.img}
                        />
                    </div>
                  </> : <div className={`${styles['empty-picture']}`}>
                      <p>Ajouter une image</p>
                  </div>}
                </InputFile>
              </div>
            </section>
        </div>
      }

    </div>
  );
}
