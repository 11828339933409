export default function hexToRgba(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

export const removeDuplicate = (arr: any[]) => {
  const set = new Set();
  return arr.reduce((finalList : any[], item) => {
    const key = `${item._id}${item.name}`;
    if (!set.has(key)) {
      finalList.push({
        ...item,
      });
    }
    set.add(key);
    return finalList;
  }, []);
};

export function calculateAverage(array: number[]) {
  if (array.length === 0) {
    return 0;
  }
  const sum = array.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    0,
  );
  const average = sum / array.length;
  return average;
}

export function formatInputDateTime(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
