import React, { useRef, useState } from 'react';
import {
  FaChevronDown, FaChevronUp, FaMinus, FaPlus,
} from 'react-icons/fa6';
// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { AppointmentType } from '../../../types/salesPoints';
import styles from './Appointments.module.scss';

const Appointment = ({ appointment }:{ appointment: AppointmentType }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const textRef = useRef<HTMLSpanElement>(null);
  const textHeight = textRef?.current?.getBoundingClientRect()?.height;
  const { lists } = useSelector((d: any) => d.salesPointsReducer);
  const type = lists.appointmentsOptions.find(
    (a: any) => a.value === appointment.type,
  )?.label;

  const start = appointment?.start ? new Date(appointment.start.replace('Z', '')).toISOString() : null;
  const date = start && format(new Date(start), 'dd.MM.yyyy HH:mm');

  const end = appointment?.end ? new Date(appointment.end.replace('Z', '')).toISOString() : null;
  const endTime = end && format(new Date(end), 'HH:mm');
  console.log(end, endTime);

  return (
    <>
      <div className={`${styles.appointment} ${styles.mobile}`}>
        <div className={styles.infos}>
          <p>{date}-{endTime || ''}</p>
          <p>{type}</p>
        </div>
        {isOpen
          && <div className={styles.comment}>
            <p>{appointment.comments}</p>
            <p>{appointment?.agent?.profile?.firstName} {appointment?.agent?.profile?.lastName}</p>
          </div>
        }
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <FaMinus /> : <FaPlus />}
        </button>
      </div>
      <div className={`${styles.appointment} ${styles.desktop}`}>
        <div className={styles.infos}>
          <p>{date}-{endTime || ''}</p>
          <p>{appointment?.agent?.profile?.firstName} {appointment?.agent?.profile?.lastName}</p>
        </div>
        <div className={styles.comment}>
          <p>{type}</p>
          <p
            style={{ height: isOpen ? textHeight : 20 }}
            className={!isOpen ? styles.ellipsis : ''}
          >
            {appointment.comments}
          </p>
          <span ref={textRef} className={styles.hidden}>
          {appointment.comments}
          </span>
        </div>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
    </>
  );
};

export default function Appointments() {
  const { appointments } = useSelector((d: any) => d.salesPointsReducer);
  return (
    <div className={styles.container}>
      {appointments?.map((a : AppointmentType, i: number) => <Appointment
        key={`appointment-${i}`}
        appointment={a}
      />)}
    </div>
  );
}
