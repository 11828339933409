import { Dispatch } from 'redux';
import {
  deleteData, getData, postData, putData,
} from '.';
import {
  CREATE_VISIT,
  DELETE_MANY_VISIT,
  DELETE_VISIT,
  GET_USER_ARCHIVED_VISITS,
  GET_USER_VISITS,
  UPDATE_SALES_POINT,
  UPDATE_VISIT,
  VISIT_ERROR,
} from './types';

export interface RequestFunction {
  (
    dispatch: Dispatch,
    data: any,
  ) : any
}

export const getUserVisitsAction = async (dispatch: Dispatch) => {
  const url = '/visits';
  const response = await getData(VISIT_ERROR, url, dispatch, true);
  if (response.data?.visits) {
    dispatch({
      type: GET_USER_VISITS,
      payload: response.data?.visits,
    });
  }
};

export const getUserArchivedVisitsAction = async (dispatch: Dispatch) => {
  const url = '/visits?archived=true';
  const response = await getData(VISIT_ERROR, url, dispatch, true);
  if (response.data?.visits) {
    dispatch({
      type: GET_USER_ARCHIVED_VISITS,
      payload: response.data?.visits,
    });
  }
};

export const createVisitAction = async (dispatch: Dispatch, data: any) => {
  const url = '/visits';
  const response = await postData(VISIT_ERROR, url, dispatch, data, true);
  if (response?.data?.visit) {
    dispatch({
      type: CREATE_VISIT,
      payload: response.data?.visit,
    });
  }
  if (response?.data?.visit?.salesPoint) {
    dispatch({
      type: UPDATE_SALES_POINT,
      payload: response?.data?.visit?.salesPoint,
    });
  }
};

export const updateVisitAction = async (dispatch: Dispatch, id: string, data: any) => {
  const url = `/visits/${id}`;
  const response = await putData(VISIT_ERROR, url, dispatch, data, true);

  if (response.data.visit) {
    if (response.data.visit.archivedAt) {
      dispatch({
        type: DELETE_VISIT,
        payload: id,
      });
    } else {
      dispatch({
        type: UPDATE_VISIT,
        payload: response.data.visit,
      });
    }
  }
  return response;
};

export const deleteVisitAction = async (dispatch: Dispatch, id: string) => {
  const url = `/visits/${id}`;
  const response = await deleteData(VISIT_ERROR, url, dispatch, true);
  if (response) {
    dispatch({
      type: DELETE_VISIT,
      payload: id,
    });
  }
  return response;
};

export const deleteManyVisitsAction = async (dispatch: Dispatch, bool: boolean) => {
  const url = `/visits/?isVisited=${bool}`;
  const response = await deleteData(VISIT_ERROR, url, dispatch, true);
  if (response) {
    dispatch({
      type: DELETE_MANY_VISIT,
      payload: bool,
    });
  }
  return response;
};

export const archiveManyVisitsAction = async (dispatch: Dispatch, bool: boolean) => {
  const url = '/visits/archive';
  const response = await getData(VISIT_ERROR, url, dispatch, true);
  if (response) {
    dispatch({
      type: DELETE_MANY_VISIT,
      payload: bool,
    });
  }
  return response;
};
